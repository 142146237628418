import PropTypes from 'prop-types';
import Events from '@activebrands/core-web/libs/Events';
import { TrackingLists } from '@activebrands/core-web/libs/Tracking/constants';
import { SearchEvents } from '@activebrands/core-web/libs/algolia/searchInsights';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ArticleCardMini from 'components/ArticleCardMini';
import ProductCardMini from 'components/product/ProductCardMini';

const Label = styled('span', {
    display: 'block',
    marginBottom: '16px',
});

const SearchListing = ({ $style = {}, label, items = [], type, searchMeta = {} }) => {
    const [css] = useStyletron();

    if (!items?.length) {
        return null;
    }

    const handleClick = (objectID, position) => {
        if (searchMeta) {
            searchMeta.objectIDs = [objectID];

            Events.trigger(SearchEvents.CLICK, {
                ...searchMeta,
                objectIDs: [objectID],
                positions: [position + 1],
                eventName: `${TrackingLists.HEADER_SEARCH}: Search`,
                queryID: searchMeta.queryID,
                index: searchMeta.index,
            });
        }
    };

    return (
        <div className={css($style)}>
            {label && <Label>{label}</Label>}
            <ul data-type={type}>
                {items.map((item, index) => {
                    const type = item.type === 'articlePage' ? 'article' : 'product';

                    return (
                        <li
                            className={css({ borderBottom: '1px solid var(--color-border-product-card-mini)' })}
                            data-product_id={item.recommendationId && item.id}
                            data-recommendation_id={item.recommendationId}
                            key={item.id}
                        >
                            {type === 'article' ? (
                                <ArticleCardMini {...item} type="colorSwatch" onClick={handleClick} />
                            ) : type === 'product' ? (
                                <ProductCardMini
                                    innerWrapperStyle={{
                                        padding: '12px',
                                    }}
                                    {...item}
                                    relatedVariations={item.relatedVariations}
                                    type="colorSwatch"
                                    media={item.media?.[0] ?? {}}
                                    searchMeta={searchMeta}
                                    onClick={() => handleClick(item.id, index)}
                                />
                            ) : null}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

SearchListing.propTypes = {
    $style: PropTypes.object,
    items: PropTypes.array,
    label: PropTypes.string,
    searchMeta: PropTypes.object,
    type: PropTypes.string,
};

export default SearchListing;
